import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducers';
import { UserState } from '../reducers/user.reducer';
import { LocaleType } from '../../models/locales.models';

export const selectUserStateFeature = (state: AppState) => state.userState;

export const isUserStateIsNull = createSelector(
    selectUserStateFeature,
    (state: UserState) => state.user === null
);

export const getUser = createSelector(
    selectUserStateFeature,
    (state: UserState) => state.user
);

export const checkIfCurrentUserByUserId = createSelector(
    selectUserStateFeature,
    (state: UserState, props: any) => {
        if (state.user) {
            return +state.user.id === props.user_id;
        }

        return false;
    }
);

export const getProfilurl = createSelector(
    selectUserStateFeature,
    (state: UserState) => state.profileUrl
);

export const getUserLanguage = createSelector(
    selectUserStateFeature,
    (state: UserState) => state.user?.settings?.locale as LocaleType | undefined
);
